<template>
  <div class="bg-white">
    <b-navbar
      toggleable="lg"
      type="light"
      :variant="y > 50 || visible ? 'white' : 'transparent'"
      :class="{ 'shadow' : y > 50 || visible }"
      sticky
    >
      <b-container>
        <b-navbar-brand :to="{ name: 'home' }">
          <b-img
            fluid
            :src="logoImg"
            alt="TechX Logo"
          />
        </b-navbar-brand>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          target="_blank"
          class="ml-auto mr-50 d-sm-none"
          href="/apply"
        >
          Apply Job
        </b-button>

        <b-navbar-toggle target="nav-collapse">
          <template #default="{ expanded }">
            <feather-icon
              v-if="expanded"
              icon="XIcon"
              size="24"
            />
            <feather-icon
              v-else
              icon="MenuIcon"
              size="24"
            />
          </template>
        </b-navbar-toggle>

        <b-collapse
          id="nav-collapse"
          is-nav
          @show="visible = true"
          @hidden="visible = false"
        >
          <b-navbar-nav class="ml-auto">
            <b-nav-item :to="{ name: 'home', hash: '#header' }">
              Home
            </b-nav-item>
            <b-nav-item :to="{ name: 'home', hash: '#service' }">
              Service
            </b-nav-item>
            <b-nav-item :to="{ name: 'home', hash: '#client' }">
              Client
            </b-nav-item>
            <b-nav-item :to="{ name: 'home', hash: '#portfolio' }">
              Portfolio
            </b-nav-item>
          </b-navbar-nav>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            target="_blank"
            class="d-none d-sm-block"
            href="/apply"
          >
            Apply Job
          </b-button>
        </b-collapse>
      </b-container>
    </b-navbar>

    <div id="header">
      <b-container>
        <b-row>
          <b-col>
            <h1
              class="title"
              style="line-height: 1"
            >
              Beyond <span class="text-primary">Developer as a Service!</span>
            </h1>
            <h4 class="subtitle">
              Don’t let a dream stay just a dream. We bring together everything that’s required to build apps
            </h4>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="dark"
              tag="router-link"
              :to="{ name: 'contact' }"
            >
              Contact Us
            </b-button>
          </b-col>
          <b-col cols="sm">
            <b-img
              fluid
              :src="heroImg"
              alt="Hero image"
            />
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div id="service">
      <b-container>
        <h2 class="title">
          What <span class="text-primary">We Do</span>
        </h2>
        <h4 class="subtitle">
          We offer service plans that fit all business types and sizes
        </h4>
        <b-row>
          <b-col
            v-for="(service, index) in services"
            :key="`service-${index}`"
            lg="4"
          >
            <div
              class="service-item"
              :class="{'has-content': service.items}"
              :tabindex="index"
            >
              <b-img
                :src="service.icon"
                alt="Service Icon"
              />
              <h4>{{ service.name }}</h4>
              <span v-if="service.items && service.items.length">See our services</span>

              <div
                v-if="service.items && service.items.length"
                class="service-content"
              >
                <ul>
                  <li
                    v-for="(item, itemIndex) in service.items"
                    :key="`service-${itemIndex}`"
                  >
                    <b-img
                      :src="checkIcon"
                      alt="Check Icon"
                    />
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-img
          fluid
          :src="serviceImg"
          alt="Bottom Image"
        />
      </b-container>
    </div>

    <div id="client">
      <b-container>
        <h2 class="title">
          Our Happy <span class="text-primary">Partners & Clients</span>
        </h2>

        <b-row>
          <b-col
            v-for="(client, index) in clients"
            :key="`client-${index}`"
            cols="4"
            lg="2"
          >
            <div class="client-item">
              <b-img
                :src="client.logo"
                alt="Client Icon"
              />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div id="portfolio">
      <b-container>
        <h2 class="title">
          Our <span class="text-primary">Portfolio</span>
        </h2>
      </b-container>

      <splide
        :options="options"
        :slides="portfolios"
        class="d-lg-none"
      >
        <splide-slide
          v-for="(portfolio, index) in portfolios"
          :key="`portfolio-${index}`"
        >
          <div class="portfolio-item">
            <div class="image-frame">
              <b-img
                class="portfolio-img"
                :src="portfolio.image"
                alt="Portfolio Image"
              />
            </div>
            <b-img
              class="portfolio-logo"
              :src="portfolio.logo"
              alt="Portfolio Logo"
            />
            <h3 class="portfolio-title">
              {{ portfolio.name }}
            </h3>
            <div class="portfolio-subtitle">
              {{ portfolio.description }}
            </div>
          </div>
        </splide-slide>
      </splide>

      <b-container class="d-none d-lg-block">
        <b-row>
          <b-col
            v-for="(portfolio, index) in portfolios"
            :key="`portfolio-${index}`"
            lg="4"
          >
            <div class="portfolio-item">
              <div class="image-frame">
                <b-img
                  class="portfolio-img"
                  :src="portfolio.image"
                  alt="Portfolio Image"
                />
              </div>
              <b-img
                class="portfolio-logo"
                :src="portfolio.logo"
                alt="Portfolio Logo"
              />
              <h3 class="portfolio-title">
                {{ portfolio.name }}
              </h3>
              <div class="portfolio-subtitle">
                {{ portfolio.description }}
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div id="contact">
      <b-container>
        <div class="section-box">
          <div class="bg-container">
            <b-row>
              <b-col
                lg="auto"
                class="description-col"
              >
                <h2 class="title">
                  Let's make <span>your digital dream</span> come true
                </h2>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="gradient-primary"
                  tag="router-link"
                  :to="{ name: 'contact' }"
                >
                  <div class="d-flex align-items-center justify-content-center">
                    Contact Us
                    <feather-icon
                      icon="ArrowRightCircleIcon"
                      class="ml-50"
                      size="18"
                    />
                  </div>
                </b-button>
              </b-col>
              <b-col class="img-col">
                <b-img
                  fluid
                  :src="humanImg"
                  alt="Human Illustration"
                />
              </b-col>
            </b-row>
          </div>
        </div>
      </b-container>
    </div>

    <Footer />
    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BFormRow,
  BContainer,
  BRow,
  BCol,
  BButton,
  BImg,
  BNavbar,
  BNavbarBrand,
  BNavbarToggle,
  BNavbarNav,
  BNavItem,
  BCollapse,
} from 'bootstrap-vue'
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import { $themeConfig } from '@themeConfig'
import { useWindowScroll } from '@vueuse/core'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import Ripple from 'vue-ripple-directive'
import Footer from '../layouts/components/Footer.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BFormRow,
    BContainer,
    BRow,
    BCol,
    BButton,
    BImg,
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BNavbarNav,
    BNavItem,
    BCollapse,
    ScrollToTop,
    Splide,
    SplideSlide,
    Footer,
  },
  data() {
    return {
      options: {
        type: 'loop',
        focus: 'center',
        arrows: false,
        autoplay: true,
        perPage: 1,
        gap: '.5rem',
        padding: {
          right: '1rem',
          left: '1rem',
        },
      },
      visible: false,
      logoImg: require('@/assets/images/logo/logo.svg'),
      heroImg: require('@/assets/images/pages/hero-img.png'),
      serviceImg: require('@/assets/images/pages/service-img.png'),
      checkIcon: require('@/assets/images/icons/check.svg'),
      humanImg: require('@/assets/images/pages/human.png'),
      services: [
        {
          name: 'Product Design & Analysis',
          icon: require('@/assets/images/icons/u_chart-pie.svg'),
          // items: [
          //   'Mobile Android',
          //   'Mobile iOS',
          //   'Mobile Web',
          //   'Web apps',
          //   'Landing Page',
          // ],
        },
        {
          name: 'All in One development',
          icon: require('@/assets/images/icons/u_brackets-curly.svg'),
          items: [
            'Mobile Android',
            'Mobile iOS',
            'Mobile Web',
            'Web apps',
            'Landing Page',
          ],
        },
        {
          name: 'Security Engineer',
          icon: require('@/assets/images/icons/u_shield-check.svg'),
          // items: [
          //   'Mobile Android',
          //   'Mobile iOS',
          //   'Mobile Web',
          //   'Web apps',
          //   'Landing Page',
          // ],
        },
        {
          name: 'QA Engineer',
          icon: require('@/assets/images/icons/u_chat-bubble-user.svg'),
          // items: [
          //   'Mobile Android',
          //   'Mobile iOS',
          //   'Mobile Web',
          //   'Web apps',
          //   'Landing Page',
          // ],
        },
        {
          name: 'Devops Engineer',
          icon: require('@/assets/images/icons/u_code-branch.svg'),
          // items: [
          //   'Mobile Android',
          //   'Mobile iOS',
          //   'Mobile Web',
          //   'Web apps',
          //   'Landing Page',
          // ],
        },
        {
          name: 'UI/UX Design',
          icon: require('@/assets/images/icons/u_web-grid-alt.svg'),
          // items: [
          //   'Mobile Android',
          //   'Mobile iOS',
          //   'Mobile Web',
          //   'Web apps',
          //   'Landing Page',
          // ],
        },
      ],
      clients: [
        {
          logo: require('@/assets/images/clients/payfazz.png'),
        },
        {
          logo: require('@/assets/images/clients/upbanx.png'),
        },
        {
          logo: require('@/assets/images/clients/miniapoly.png'),
        },
        {
          logo: require('@/assets/images/clients/stafbook.png'),
        },
        {
          logo: require('@/assets/images/clients/credibook.png'),
        },
        {
          logo: require('@/assets/images/clients/nubie.png'),
        },
        {
          logo: require('@/assets/images/clients/verihubs.png'),
        },
        {
          logo: require('@/assets/images/clients/maqmur.png'),
        },
        {
          logo: require('@/assets/images/clients/retas.png'),
        },
        {
          logo: require('@/assets/images/clients/fazzcard.png'),
        },
        {
          logo: require('@/assets/images/clients/payrollbird.png'),
        },
        {
          logo: require('@/assets/images/clients/sellfazz.png'),
        },
      ],
      portfolios: [
        {
          name: 'Stafbook',
          description: 'HR Management System',
          logo: require('@/assets/images/logo/stafbook.png'),
          image: require('@/assets/images/portfolios/stafbook.png'),
        },
        {
          name: 'Upbanx',
          description: 'Creator Financing Platform',
          logo: require('@/assets/images/logo/upbanx.png'),
          image: require('@/assets/images/portfolios/upbanx.png'),
        },
        {
          name: 'Miniapoly',
          description: 'Moms & Baby Marketplace',
          logo: require('@/assets/images/logo/miniapoly.png'),
          image: require('@/assets/images/portfolios/miniapoly.png'),
        },
      ],
    }
  },
  setup() {
    const { y } = useWindowScroll()

    const { enableScrollToTop } = $themeConfig.layout

    return { y, enableScrollToTop }
  },
}
</script>

<style lang="scss" scoped>
@import 'src/@core/scss/vue/pages/page-home.scss';
@import "~@splidejs/splide/dist/css/themes/splide-default.min.css";
</style>
