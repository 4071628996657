var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white"},[_c('b-navbar',{class:{ 'shadow' : _vm.y > 50 || _vm.visible },attrs:{"toggleable":"lg","type":"light","variant":_vm.y > 50 || _vm.visible ? 'white' : 'transparent',"sticky":""}},[_c('b-container',[_c('b-navbar-brand',{attrs:{"to":{ name: 'home' }}},[_c('b-img',{attrs:{"fluid":"","src":_vm.logoImg,"alt":"TechX Logo"}})],1),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var expanded = ref.expanded;
return [(expanded)?_c('feather-icon',{attrs:{"icon":"XIcon","size":"24"}}):_c('feather-icon',{attrs:{"icon":"MenuIcon","size":"24"}})]}}])}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""},on:{"show":function($event){_vm.visible = true},"hidden":function($event){_vm.visible = false}}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item',{attrs:{"to":{ name: 'home', hash: '#header' }}},[_vm._v(" Back Home ")])],1)],1)],1)],1),_c('div',{attrs:{"id":"header"}},[_c('b-container',[_c('div',{staticClass:"inner-container"},[_c('h2',{staticClass:"title"},[_vm._v(" Become a Client ")]),_c('h6',{staticClass:"subtitle"},[_vm._v(" Our clients get the best results when they have our team dedicated to their business for extended periods of time. Ready to move forward? ")]),_c('b-row',[_c('b-col',{attrs:{"cols":"lg","order-lg":"2"}},[_c('b-card',[_c('validation-observer',{ref:"formRules"},[_c('b-form',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Your Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"email","placeholder":"Email"},model:{value:(_vm.emailValue),callback:function ($$v) {_vm.emailValue=$$v},expression:"emailValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Message....","rows":"3"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"dark","type":"submit","block":"","disabled":_vm.loadingSubmit},on:{"click":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.loadingSubmit ? 'Sending...' : 'Send Your Idea')+" ")])],1)],1)],1)],1),_c('b-col',{attrs:{"order-lg":"1"}},[_c('b-img',{staticClass:"d-none d-lg-block",attrs:{"fluid":"","src":_vm.heroImg,"alt":"Hero image"}}),_c('b-img',{staticClass:"d-lg-none",attrs:{"fluid":"","src":_vm.heroSmImg,"alt":"Hero image"}})],1)],1)],1)])],1),_c('Footer'),(_vm.enableScrollToTop)?_c('scroll-to-top'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }