<template>
  <div id="footer">
    <div class="footer-top">
      <b-container>
        <b-row>
          <b-col cols="lg">
            <b-img
              class="logo-img"
              :src="logoImg"
              alt="TechX Logo"
            />
            <h5 class="mt-50 mb-75">
              PT Sinergi Selaras Bersama
            </h5>
            <p>
              TechX’s mission is to provide everyone in Southeast Asia with the platform to learn from quality teachers, practice with real work experiences, and get hired by top companies in the world.
            </p>
          </b-col>
          <b-col cols="lg">
            <div class="title">
              Company
            </div>
            <ul>
              <li
                v-for="(item, index) in contacts"
                :key="`contact-${index}`"
              >
                <b-img
                  :src="item.icon"
                  alt="Icon"
                />
                <b-link
                  :href="item.href"
                  :target="item.target"
                >
                  {{ item.description }}
                </b-link>
              </li>
            </ul>
          </b-col>
          <b-col
            v-if="links.length"
            lg="2"
          >
            <div class="title">
              Quick Link
            </div>
            <ul>
              <li
                v-for="(item, index) in links"
                :key="`link-${index}`"
              >
                <b-link :to="item.to">
                  {{ item.title }}
                </b-link>
              </li>
            </ul>
          </b-col>
          <b-col
            v-if="socials.length"
            lg="2"
          >
            <div class="title">
              Social Media
            </div>
            <ul>
              <li
                v-for="(item, index) in socials"
                :key="`social-${index}`"
              >
                <b-link
                  :href="item.href"
                  :target="item.target"
                >
                  {{ item.title }}
                </b-link>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="footer-bottom">
      <b-container>
        TechX © {{ currentYear }}. All rights reserved.
      </b-container>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BContainer,
  BRow,
  BCol,
  BImg,
  BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BImg,
    BLink,
  },
  data() {
    return {
      logoImg: require('@/assets/images/logo/logo.svg'),
      locationPinIcon: require('@/assets/images/icons/u_location-pin-alt.svg'),
      phoneIcon: require('@/assets/images/icons/u_phone-volume.svg'),
      mailBoxIcon: require('@/assets/images/icons/u_mailbox.svg'),
      currentYear: new Date().getFullYear(),
      contacts: [
        {
          description: 'Menara Prima, Jakarta, Indonesia',
          icon: require('@/assets/images/icons/u_location-pin-alt.svg'),
          href: 'https://goo.gl/maps/4amxQZx2YnEizb5c8',
          target: '_blank',
        },
        // {
        //   description: '+62813-9304-3073',
        //   icon: require('@/assets/images/icons/u_phone-volume.svg'),
        //   href: 'tel:+6281393043073',
        // },
        {
          description: 'hi@techx.id',
          icon: require('@/assets/images/icons/u_mailbox.svg'),
          href: 'mailto:hi@techx.id',
        },
      ],
      links: [
        {
          title: 'Service',
          to: { name: 'home', hash: '#service' },
        },
        {
          title: 'Client',
          to: { name: 'home', hash: '#client' },
        },
        {
          title: 'Portfolio',
          to: { name: 'home', hash: '#portfolio' },
        },
      ],
      socials: [
        // {
        //   title: 'Facebook',
        //   href: 'https://facebook.com',
        //   target: '_blank',
        // },
        // {
        //   title: 'Instagram',
        //   href: 'https://instagram.com',
        // },
      ],
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/components/footer.scss';
</style>
